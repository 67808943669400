"use client";
import useSWR, { preload } from "swr";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	AdvancedMarker,
	APILoadingStatus,
	InfoWindow,
	Map,
	Pin,
	useAdvancedMarkerRef,
	useApiLoadingStatus,
	useMap,
	useMapsLibrary,
} from "@vis.gl/react-google-maps";

import { siteAPIURL, SiteName } from "@/utilities/constants";
import { MaterialIcon } from "../../shared/MaterialIcon/MaterialIcon";
import { MaterialSymbol } from "material-symbols";
import { GeoJsonData, Line, Lines } from "./helpers";
import styles from "./PipelineLocatorMap.module.scss";
import { Button } from "../../shared/Button/Button";
import clsx from "clsx";
import { LoadingSpinner } from "../../shared/LoadingSpinner/LoadingSpinner";
import LinkSolstice from "../../shared/LinkSolstice/LinkSolstice";

const fetcher = (url: string | URL | Request) => fetch(url).then(r => r.json());

const PROXIMITY_THRESHOLD_FALLBACK = 60; // meters
const POINT_PERCENTAGE_PER_LINE = 0.8;

// Get geojson data from the CMS and pass it to the map
const TAS_INITIAL_POSITION = {
	center: { lat: -42.182737, lng: 146.670465 },
	zoom: 7,
	searchBounds: {
		north: -39.573353,
		south: -43.740952,
		west: 143.56139,
		east: 148.479,
	},
};

const VIC_INITIAL_POSITION = {
	center: { lat: -36.466545, lng: 144.953582 },
	zoom: 7,
	searchBounds: {
		north: -34.0,
		south: -39.0,
		west: 140.96,
		east: 150.0,
	},
};
const INITIAL_POSITION =
	process.env.NEXT_PUBLIC_SITE == SiteName.Solstice ? TAS_INITIAL_POSITION : VIC_INITIAL_POSITION;
const INITIAL_CENTER = INITIAL_POSITION.center;
const INITIAL_ZOOM = INITIAL_POSITION.zoom;
const MAP_SEARCH_BOUNDS = INITIAL_POSITION.searchBounds;

const RED_LINE_INDICATOR_WORDING =
	"A red line indicates the presence of the gas pipeline but should be used as a guide only. Actual location of pipework may vary.";

const TAS_CONTENT_WORDING = {
	found: {
		contentBold: "It looks like the natural gas pipeline is right in front of your house!",
		content: "If you'd like to connect, simply apply",
		contentLink: {
			text: "Online",
			// TODO: when the site is built, replace it with real link
			url: "/apply-online",
		},
		contentItalic: RED_LINE_INDICATOR_WORDING,
	},
	unFound: {
		contentBold: "",
		content: `Your property doesn't appear to be in front of the gas pipeline. If you think this isn't right, please give us a call on 1800 750 750 for assistance.`,
		contentItalic: RED_LINE_INDICATOR_WORDING,
		contentLink: null,
	},
};

const VIC_CONTENT_WORDING = {
	found: {
		contentBold: TAS_CONTENT_WORDING.found.contentBold,
		content: "If you have an existing connection, simply",
		contentLink: {
			text: "set up a new account",
			// TODO: when the site is built, replace it with real link
			url: "/set-up-a-new-account",
		},
		contentItalic: TAS_CONTENT_WORDING.found.contentItalic,
	},
	unFound: {
		contentBold: "",
		content: TAS_CONTENT_WORDING.unFound.content,
		contentItalic: TAS_CONTENT_WORDING.unFound.contentItalic,
		contentLink: null,
	},
};

const PIPELINE_WORDING = {
	found: {
		icon: {
			name: "check_circle",
			color: "success",
		},
		heading: "Congratulations!",
		...VIC_CONTENT_WORDING.found,
		...(process.env.NEXT_PUBLIC_SITE == SiteName.Solstice && TAS_CONTENT_WORDING.found),
	},
	unFound: {
		icon: {
			name: "warning",
			color: "warn",
		},
		heading: "Sorry!",
		...VIC_CONTENT_WORDING.unFound,
		...(process.env.NEXT_PUBLIC_SITE == SiteName.Solstice && TAS_CONTENT_WORDING.unFound),
	},
};

interface PipelineLocatorMapProps {
	proximityThreshold?: number | null;
	address?: string | null;
	callback?: (b: boolean) => void;
}

export const PipelineLocatorMap = ({
	proximityThreshold = PROXIMITY_THRESHOLD_FALLBACK,
	address,
	callback,
}: PipelineLocatorMapProps) => {
	const {
		data: geojson,
		error,
		isLoading: isPipeLineLoading,
	} = useSWR(siteAPIURL + "/pipeline-locator", fetcher);

	const GOOGLE_MAPS_MAP_ID = process.env.NEXT_PUBLIC_GOOGLE_MAPS_MAP_ID || "";

	const selectivePointsInLine = (percentage: number, line?: Line) => {
		if (!line) return [];
		if (percentage <= 0) return [];
		if (percentage >= 1) return line;

		const pointsNum = Math.round(line.length * percentage);

		const interval = (line.length - 1) / (pointsNum - 1);
		const result = [];

		for (let i = 0; i < pointsNum; i++) {
			result.push(line[Math.round(i * interval)]);
		}

		return result;
	};

	const refineData = useCallback((geoData: GeoJsonData, refinePercentage: number): Line[] => {
		// Retrieve all lines, and they are grouped by features
		const lines = geoData.features.map(feature => feature.geometry.coordinates as Line | undefined);
		// The refine is just retrieve a certain percentage of points in each line with the same interval to speed up the proximity calculation
		const shortenedLineCoords = lines.map(line => selectivePointsInLine(refinePercentage, line));
		return shortenedLineCoords;
	}, []);

	const apiStatus: APILoadingStatus = useApiLoadingStatus();

	const map = useMap();

	const places = useMapsLibrary("places");
	const inputRef = useRef<HTMLInputElement>(null);

	const [markerRef, marker] = useAdvancedMarkerRef();
	const [placeAutocomplete, setPlaceAutocomplete] =
		useState<google.maps.places.Autocomplete | null>(null);
	const [lines, setLines] = useState<Lines>([]);

	const [markerPosition, setMarkerPosition] = useState<google.maps.LatLng | null>(null);

	const [isInfoWindowShown, setIsInfoWindowShown] = useState(false);
	const [isNearPipeline, setIsNearPipeline] = useState(false);

	const pipelineWording = isNearPipeline ? PIPELINE_WORDING.found : PIPELINE_WORDING.unFound;

	// add pipeline data to the map
	useEffect(() => {
		if (!geojson || !geojson.pipelineData) return;

		map?.data.addGeoJson(geojson.pipelineData);
		map?.data.setStyle({
			strokeColor: "#ff5647",
			strokeWeight: 2,
		});

		const shortenedLinesCoords = refineData(geojson.pipelineData, POINT_PERCENTAGE_PER_LINE);
		setLines(shortenedLinesCoords);
	}, [geojson, map, refineData]);

	const processPlace = useCallback(
		(place: google.maps.places.PlaceResult | undefined | null) => {
			if (!place) return;
			const location = place.geometry?.location;
			if (!location) return;
			setMarkerPosition(location);

			setIsInfoWindowShown(true);

			const isNear = checkProximity(location, lines, proximityThreshold ?? 0);
			setIsNearPipeline(isNear);
			callback?.(!!isNear);
		},
		[lines, proximityThreshold, callback],
	);

	const onPlaceSelect = useCallback(
		(place: google.maps.places.PlaceResult) => {
			processPlace(place);
		},
		[processPlace],
	);

	if (!placeAutocomplete && places && inputRef.current) {
		const options = {
			fields: ["geometry", "name", "formatted_address"],
			bounds: MAP_SEARCH_BOUNDS,
			region: "au",
			strictBounds: true,
		};

		if (places && inputRef.current) {
			setPlaceAutocomplete(new places.Autocomplete(inputRef?.current, options));
		}
	}

	useEffect(() => {
		if (!placeAutocomplete) return;
		placeAutocomplete.addListener("place_changed", () => {
			onPlaceSelect(placeAutocomplete.getPlace());
		});
	}, [onPlaceSelect, placeAutocomplete, places]);

	const onSearchButtonClick = useCallback(() => {
		// make sure there is a value
		if (!inputRef.current?.value) return;
		if (!places) return;
		if (!map) return;

		// get place from the input field and  PlacesService
		const request = {
			query: inputRef.current?.value,
			fields: ["location"],
			locationBias: MAP_SEARCH_BOUNDS,
			region: "au",
		};
		const service = new places.PlacesService(map);
		service.textSearch(request, (results, status) => {
			if (status === "OK") {
				if (!results || results.length === 0) {
					console.log("No results found");
					return;
				}

				processPlace(results[0]);
			} else {
				console.log("No results found, status: ", status);
			}
		});

		// const place = markerPosition ? { geometry: { location: markerPosition } } : null;
		// processPlace(place);
	}, [map, places, processPlace]);

	useEffect(() => {
		if (address && address.length > 0 && inputRef.current) {
			inputRef.current.value = address;
		}

		if (map && !isPipeLineLoading && lines.length > 0 && places) {
			onSearchButtonClick();
		}
	}, [address, isPipeLineLoading, lines, map, onSearchButtonClick, places]);

	const checkProximity = (
		position: google.maps.LatLng,
		allLines: Lines,
		proximityThreshold: number,
	): boolean => {
		let result = false;
		allLines.forEach(lines => {
			for (let i = 0; i < lines.length - 1; i++) {
				if (i + 1 >= lines.length) break;
				// The original data is [lng, lat], but the google map LatLng is [lat, lng]
				const start = new google.maps.LatLng(lines[i][1], lines[i][0]);
				const end = new google.maps.LatLng(lines[i + 1][1], lines[i + 1][0]);
				// Form a line from start to end and get the middle point
				const interpolatedPoint = google.maps.geometry.spherical.interpolate(start, end, 0.5); // middle point of the line
				// Calculate the distance between the middle point and the position for proximity calculation
				const dist = google.maps.geometry.spherical.computeDistanceBetween(
					interpolatedPoint,
					position,
				);
				if (dist < proximityThreshold) {
					result = true;
					return;
				}
			}
			if (result) return;
		});

		return result;
	};

	useEffect(() => {
		if (!markerPosition) return;
		map?.setZoom(17);

		map?.setCenter(markerPosition);
	}, [markerPosition, map]);

	if (apiStatus === APILoadingStatus.AUTH_FAILURE || apiStatus === APILoadingStatus.FAILED) {
		return (
			<div className={styles["pipeline-error"]}>
				<h2 className={styles["pipeline-error__heading"]}>
					Unable to load the Pipeline Locator map.
				</h2>
				<p className={styles["pipeline-error__message"]}>Please try again later.</p>
			</div>
		);
	}

	return (
		<div className={styles["pipeline-map"]}>
			{geojson && !isPipeLineLoading && apiStatus !== APILoadingStatus.LOADING ? (
				<>
					<div className={styles["pipeline-map__header"]}>
						{!address ? (
							<>
								<div
									className={clsx(styles["pipeline-map__input-wrapper"], "autocomplete-container")}
								>
									<input
										className={styles["pipeline-map__input"]}
										placeholder="Search your address"
										ref={inputRef}
									/>
									<div className={styles["pipeline-map__icon"]}>
										<MaterialIcon name="search" />
									</div>
								</div>

								<Button
									className={styles["pipeline-map__button"]}
									showArrow
									type="button"
									onClick={onSearchButtonClick}
									label="Search"
								/>
							</>
						) : (
							<input
								type="hidden"
								ref={inputRef}
							/>
						)}
					</div>
					<Map
						className={styles["pipeline-map__map"]}
						mapId={GOOGLE_MAPS_MAP_ID}
						defaultCenter={INITIAL_CENTER}
						defaultZoom={INITIAL_ZOOM}
						fullscreenControl={false}
						mapTypeControl={false}
						streetViewControl={false}
						clickableIcons={false}
						restriction={{
							latLngBounds: MAP_SEARCH_BOUNDS,
							strictBounds: false,
						}}
					/>

					<AdvancedMarker
						position={markerPosition}
						className="pipeline-map__marker"
						ref={markerRef}
						onClick={() => setIsInfoWindowShown(true)}
					>
						<Pin
							background={"#9646ff"}
							glyphColor={"#fff"}
							borderColor={"#9646ff"}
						/>
						{isInfoWindowShown && (
							<>
								<InfoWindow
									disableAutoPan
									className={styles["info-window"]}
									onCloseClick={() => setIsInfoWindowShown(false)}
									anchor={marker}
								>
									<button
										className={styles["info-window__close"]}
										onClick={() => setIsInfoWindowShown(false)}
									>
										<MaterialIcon name={"close"} />
									</button>

									<div className={styles["info-window__content"]}>
										<div className={styles["info-window__header"]}>
											<MaterialIcon
												name={pipelineWording.icon.name as MaterialSymbol}
												className={clsx(
													styles["info-window__icon"],
													styles[`info-window__icon--${pipelineWording.icon.color}`],
												)}
											/>
											<div className={styles["info-window__heading"]}>
												{pipelineWording.heading}
											</div>
										</div>

										<div>
											{pipelineWording.contentBold && (
												<p>
													<strong>{pipelineWording.contentBold}</strong>
												</p>
											)}
											{pipelineWording.content && (
												<p>
													{pipelineWording.content}
													{pipelineWording.contentLink && (
														// add space before link
														<>
															{" "}
															<LinkSolstice href={pipelineWording.contentLink.url}>
																{pipelineWording.contentLink.text}
															</LinkSolstice>
														</>
													)}
												</p>
											)}

											<p>
												<i>{pipelineWording.contentItalic}</i>
											</p>
										</div>
									</div>
								</InfoWindow>
							</>
						)}
					</AdvancedMarker>
				</>
			) : (
				<LoadingSpinner
					className={styles["pipeline-map__loading"]}
					theme="light"
					size="xl"
				/>
			)}
		</div>
	);
};
