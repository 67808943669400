import clsx from "clsx";
import styles from "./RadioInput.module.scss";
import { ForwardedRef, forwardRef } from "react";

interface RadioInputProps extends React.ComponentPropsWithRef<"input"> {
	label: string;
	inputClassName?: string;
	labelClassName?: string;
}

export const RadioInput = forwardRef(
	(
		{ value, label, className, inputClassName, labelClassName, ...inputProps }: RadioInputProps,
		ref: ForwardedRef<HTMLInputElement>,
	) => {
		return (
			<label className={clsx(styles["radio"], className)}>
				<input
					ref={ref}
					value={value}
					type="radio"
					className={clsx(styles["radio__input"], inputClassName)}
					{...inputProps}
				/>

				<span className={clsx(styles["radio__label"], labelClassName)}>{label}</span>
			</label>
		);
	},
);
RadioInput.displayName = "RadioInput";
