"use client";
import { APIProvider } from "@vis.gl/react-google-maps";

const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "";

interface PipelineLocatorGoogleMapsAPIProviderProps {
	children: React.ReactNode;
}

export const PipelineLocatorGoogleMapsAPIProvider = ({
	children,
}: PipelineLocatorGoogleMapsAPIProviderProps) => {
	return <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>{children}</APIProvider>;
};
